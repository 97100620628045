<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col
        md="3"
        cols="12"
      >
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="validationForm()">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-form-group
                    label="Kata Kunci"
                    label-for="vi-keyword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kata Kunci"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-keyword"
                        v-model="keyword"
                        required
                        name="keyword"
                        placeholder="Kata Kunci"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-form-group
                    label="Instansi"
                    label-for="vi-instansi_id"
                  >
                    <v-select
                      id="vi-instansi_id"
                      v-model="instansi_id"
                      name="instansi_id"
                      :options="referenceData.ref_instansi"
                      placeholder="Nama Instansi"
                      label="name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                  >
                    Cari
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>

      <b-col
        md="9"
        cols="12"
      >
        <b-card>
          <h3 class="mb-1">
            Hasil Pencarian
          </h3>
          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div
                v-if="alertDetail.variant === 'danger'"
                class="alert-body"
              >
                <ul v-if="typeof alertDetail.text === 'object'">
                  <li
                    v-for="(item, index) in alertDetail.text"
                    :key="index"
                  >
                    {{ index }}:
                    <span
                      v-for="(child, indexs) in item"
                      :key="indexs"
                    >
                      {{ child }}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                v-else
                class="alert-body"
              >
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length
            }"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span
                v-else-if="props.column.field === 'nama_kegiatan'"
                class="text-wrap"
              >
                <b-link
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  class="item-review text-primary"
                >
                  {{ props.row.nama_kegiatan }}
                </b-link>
              </span>
              <span v-else-if="props.column.field === 'nama_app_infra'">
                <b-badge
                  variant="primary"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama_app_infra }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <!-- Lihat Rekomendasi -->
              <span v-else-if="props.column.field == 'rekomendasi'">
                <b-button
                  v-if="props.row.rekomendasi"
                  :variant="statusVariant(props.row.rekomendasi).class"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.rekomendasi).title }}
                </b-button>
              </span>
              <!-- Lihat Status -->
              <span v-else-if="props.column.field == 'status'">
                <b-button
                  v-if="props.row.status"
                  :variant="statusVariant(props.row.status).class"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['15', '30', '60', '100']"
                    class="mx-1"
                    @change="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="selectPage"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>

        </b-card>
      </b-col>
      <!-- post -->
    </b-row>
  </section>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BButton, BCol, BCard, BFormGroup, BAlert,
  BFormInput, BLink, BPagination, BFormSelect, BBadge,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BPagination,
    BFormSelect,
    BBadge,
    BButton,
    BAlert,
    BCard,
    BRow,
    BCol,
    BLink,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      required,
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      keyword: '',
      instansi_id: '',
      daftarAppInfra: [
        { value: 'app', text: 'Aplikasi' },
        { value: 'infra', text: 'Infrastruktur' },
      ],
      columns: [
        {
          thClass: 'text-center',
          label: 'K/L',
          field: 'prefix',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Aplikasi / Infrastruktur',
          field: 'nama_app_infra',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          field: 'anggaran',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Perubahan Terakhir',
          field: 'last_update',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi',
          field: 'rekomendasi',
          width: '160px',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          field: 'status',
          width: '160px',
          sortable: false,
        },
      ],
      rows: [],
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        sort_by: [
          {
            column: 'last_update',
            asc_desc: 'asc',
          },
        ],
        start: 0,
        length: 30,
        asc_desc: 'asc',
      },
    }
  },
  mounted() {
    this.getReference()
  },
  methods: {
    statusVariant(status) {
      const statusColor = {
        Dilanjutkan: {
          title: 'Dilanjutkan',
          class: 'outline-success',
        },
        'Tidak Dilanjutkan': {
          title: 'Tidak Dilanjutkan',
          class: 'outline-danger',
        },
        'Belum Selesai': {
          title: 'Belum Selesai',
          class: 'outline-warning',
        },
        Dispute: {
          title: 'Dispute',
          class: 'outline-warning',
        },
      }
      if (statusColor[status]) {
        return statusColor[status]
      }
      return {
        title: status,
        class: 'outline-secondary',
      }
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateParams({ start: 0 })
          this.cariData()
        }
      })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.cariData()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.cariData()
    },
    cariData() {
      this.rows = []
      this.updateParams({ keyword: this.keyword })

      if (this.instansi_id !== '') {
        this.serverParams.instansi_id = this.instansi_id.instansi_id
      }

      this.$http.post(`/search?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
